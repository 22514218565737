import React from "react";
import Nav from "./Nav";
import { Link } from "react-router-dom";

function WhitlistArea() {
  //make timer for whitlist
  //days hours minutes seconds
  
  //set whitelist date as 17th october 9pm utc time
  const whitelist = new Date("October 17, 2022 21:00:00 UTC");
  var today = new Date();
  const diffTime = whitelist.getTime() - today.getTime();

  // Time calculations for days, hours, minutes and seconds. Set them as 0 if they are negative
  const [days, setDays] = React.useState(Math.max(Math.floor(diffTime / (1000 * 60 * 60 * 24)), 0));
  const [hours, setHours] = React.useState(Math.max(Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)), 0));
  const [minutes, setMinutes] = React.useState(Math.max(Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60)), 0));
  const [seconds, setSeconds] = React.useState(Math.max(Math.floor((diffTime % (1000 * 60)) / 1000), 0));
  const [timeUp, setTimeUp] = React.useState(diffTime < 0 ? true : false);

  //reduce the time by 1 second every second and update the state with the new time left until the end of the countdown date is reached (0) and then stop the timer and show the message that the countdown has ended (timeUp) and then stop the timer and show the message that the countdown has ended (timeUp)
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          if (hours === 0) {
            if (days === 0) {
              clearInterval(interval);
              setTimeUp(true);
            } else {
              setDays(days - 1);
              setHours(23);
              setMinutes(59);
              setSeconds(59);
            }
          } else {
            setHours(hours - 1);
            setMinutes(59);
            setSeconds(59);
          }
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      } 
    }, 1000);
    // print the time left
    return () => clearInterval(interval);
  }, [seconds, minutes, hours, days]);

  return (

    <>
    <div className="banner">
             <Nav bgImg="linear-gradient(rgba(0, 0, 0, 0.475), rgba(0, 0, 0, 0.091)), url(/Assets/bg.jpg)" />
             <img src="/Assets/bg.jpg" alt="" />
             {timeUp ? 
             <Link to="/mint">
               <button className=" mint_btn ">MINT NOW</button>
             </Link>
             :
             <></>}
     </div>
   <section>
 <div className="time ">
   <h1 className="mb-3">WHITELIST MINT IN :</h1>
   <div className="d-flex gap-md-5 gap-4 time__whitlist">
     <div>
       <h3>{days}</h3>
     </div>
     <div>
       <h3>{hours}</h3>
     </div>
     <div>
       <h3>{minutes}</h3>
     </div>
     <div>
       <h3>{seconds}</h3>
     </div>
   </div>
 </div>
</section>
</>
  );
}

export default WhitlistArea;