import "./App.css";
import Header from "./components/Header";
import Nav from "./components/Nav";
import Team from "./components/Team";
import JoinComunity from "./components/JoinComunity";
import "./css/story__team.css";
import OurStory from "./components/OurStory";
import WhitlistArea from "./components/WhitlistArea";
import RoadMap from "./components/RoadMap";
import TheDevelopment from "./components/TheDevelopment";
import Faqs from "./components/Faqs";
import Footer from "./components/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MintPage from "./components/MintPage";
import { Link } from "react-router-dom";

function App() {

  return (
    <>
      <div className="App">
        {/* banner */}
        <Routes>
          <Route
            path="/"
            element={
              <>
                {/* banner  end*/}

                <WhitlistArea />
                <OurStory />
                <RoadMap />
                {/* <TheDevelopment /> */}
                <JoinComunity />
                <Team />
                <Faqs />
                <Footer />
              </>
            }
          />
          <Route
            path="/mint"
            element={
              <>
                <Nav hide={true} />
                <MintPage />
              </>
            }
          />
        </Routes>
      </div>
    </>
  );
}

export default App;
