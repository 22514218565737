import React from "react";
import "../css/faqs.css";
import Faq from "react-faq-component";

function Faqs() {
  const data = {
    title: "FAQ (How it works)",
    rows: [
      {
        title: "When is the official launch?",
        content: `It will be the 14th of october`,
      },
      {
        title: "How do I get whitelisted?",
        content: `Join our discord and participate with the community.`,
      },
      {
        title: "How can I buy a turtle society NFT?",
        content: `If you are a whitelisted address, you will be able to mint the NFT as soon as the minting process starts.
        
                  If you are not whitelisted, you will be able to buy the NFT on OpenSea.
                  
                  The minting process will start on the 14th of october.
                  
                  As for the public minting, it will be announced on our discord.`,
      },
      {
        title: "How can I view my NFT?",
        content: `Once the collection is officially launched you will be able to mint from this website and see your NFT thought opensea.`,
      },
    ],
  };

  const styles = {
    bgColor: "transparent",
    titleTextColor: "white",
    titleTextSize: "48px",
    rowTitleColor: "red",
    // rowContentColor: 'grey',
    arrowColor: "white",
    rowTitleTextSize: "large",
    rowContentColor: "white",
    rowContentTextSize: "16px",
    rowContentPaddingTop: "10px",
    rowContentPaddingBottom: "10px",
  };

  const config = {
    animate: true,
    arrowIcon: "+",
    expandIcon: "+",
    collapseIcon: "-",
    tabFocus: true,
  };
  return (
    <section className="faqs">
      <div className="container">
        <div>
          <Faq data={data} styles={styles} config={config} />
        </div>
      </div>
    </section>
  );
}

export default Faqs;
