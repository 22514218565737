import React from "react";
import "../css/roadmap.css";

function RoadMap() {
  return (
    <section className="roadmap">
      <div className="container">
        <h1 className="text-center mb-3">ROADMAP 1.0</h1>
        <p className="text-center">
          Please Join Our Discord For A Details Roadmap
        </p>

        <div className="roadmap__start">
          <h1 className="roadmap__text">THE BEGINING</h1>

          <div className="roadmap__start row">
            <div className="roadmap__left col-5">
              <img className="" src="assets/no-bg2.png" alt="" />
            </div>
            <div className="roadmap__mid2 col-2 ">
              <div className="mid__line1">
                <div className="mid__circle">1</div>
              </div>
            </div>
            <div className="roadmap__right col-5">
              <h1>Stage 1</h1>
              <p>Launch 200 bored turtles for whitelist wallets</p>
              <p>Launch 4800 bored turtles collection for the public</p>
              <p>Rarity Sniper Integration</p>
            </div>
          </div>

          <div className="roadmap__start row">
            <div className="roadmap__left col-5">
              <h1>STAGE 2</h1>
              <p>Donate 5,000 USD to help save baby turtles.</p>
              <p>Exclusive holder's merchandise and commercial rights.</p>
            </div>
            <div className="roadmap__mid col-2 ">
              <div className="mid__line1">
                <div className="mid__circle">2</div>
              </div>
            </div>
            <div className="roadmap__right col-5">
              <div></div>
            </div>
          </div>

          <div className="roadmap__start row">
            <div className="roadmap__left col-5">
              <img className="" src="assets/no-bg1.png" alt="" />
            </div>
            <div className="roadmap__mid2 col-2 ">
              <div className="mid__line1 border__radbot">
                <div className="mid__circle">3</div>
              </div>
            </div>
            <div className="roadmap__right col-5">
              <h1>STAGE 3</h1>
              <p>Baby Turtle drops for all holders.</p>
              <p>10,000 USD holders giveaway.</p>
              <p>Launch mutant turtles collection.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RoadMap;
