import React from "react";

function TheDevelopment() {
  return (
    <section>
      <div className="container">
        <h1 className="text-center mb-3">THE DEVELOPMENT </h1>
        <p className="text-center">
          Please Join Our Discord For A Details Roadmap
        </p>

        <div className="roadmap__start">
          <div className="roadmap__start row">
            <div className="roadmap__left col-5">
              <img className="" src="assets/no-bg1.png" alt="" />
            </div>
            <div className="roadmap__mid2 col-2 ">
              <div className="mid__line">
                <div className="mid__circle">75%</div>
              </div>
            </div>
            <div className="roadmap__right col-5">
              <h1>SELL OUT</h1>
              <p>
                Launch $dinos token. $Dinos are our ERC-20 in-game currency used to power the dino society ecosystem.
              </p>
              <p>We are still making decisions on how rewards and incentive opportunities will operate so we will update you as soon as possible.</p>
              <p>
                Acquired land in the sandbox as soon as the project sold out and prepare for our dino playground in the metaverse
              </p>
            </div>
          </div>

          <div className="roadmap__start row">
            <div className="roadmap__left col-5">
              <h1>SELL OUT</h1>
              <p>
                Launch 3D companion collection. Dino Society NFT holders will be able to claim their companions. Limited time apply, unclaimed companions will be available for public sale.
              </p>
              <p>Initiate liquidity pool and airdrop token to our holder. Staking activated. 25% secondary fee will be permanently dedicated to providing liquidity for our token $DINOS.</p>
            </div>
            <div className="roadmap__mid2 col-2 ">
              <div className="mid__line1 border__radbot">
                <div className="mid__circle">100%</div>
              </div>
            </div>
            <div className="roadmap__right col-5">
              <img className="" src="assets/no-bg1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TheDevelopment;
