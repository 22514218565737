import React from "react";
import "../css/joincomunity.css";

function JoinComunity() {
  return (
    <section className="community">
      <div className=" container">
        <h1 className="my-5 text-center">JOIN THE COMMUNITY</h1>
        <div className="row align-items-center justify-content-between">
          <div className="col-md-7">
            <p className="content__text">
              We have some amazing plans for our future! We invite you to check
              our roadmap. You will also be helping save baby turtles since part
              of the money will go towards that.
            </p>

            <div className="d-flex gap-3 mt-4 mt-md-5">
              <a href="https://discord.gg/f5MdWByZej" target="__blank">
                <button className="btn__1">
                  <i className="bi bi-discord " />
                  JOIN OUR DISCORD
                </button>
              </a>
              <a href="https://twitter.com/bored_nation" target="__blank">
                <button className="btn__2">
                  <i className="bi bi-twitter " /> FOLLOW US
                </button>
              </a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="outer__div78">
              <img src="/Assets/a.png" alt="" className="w-100 img__1" />
              <img src="/Assets/b.png" alt="" className="img__2" />
              <img src="/Assets/c.png" alt="" className="img__3" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default JoinComunity;
