import React from "react";

function Team() {
  return (
    <section className="team">
      <div className="container ">
        <h1 className="text-center my-5">OUR TEAM</h1>
        <div className="nft__team">
          <div className="nft__teammembers border">
            <img src="/Assets/b.png" alt="" />
            <h1>Founder</h1>
            <h4 className="my-2">Mike G</h4>
            <i className="bi bi-twitter" />
          </div>
          <div className="nft__teammembers border">
            <img src="/Assets/c.png" alt="" />
            <h1>Marketing Lead</h1>
            <h4 className="my-2">Mike G</h4>
            <i className="bi bi-twitter" />
          </div>
          <div className="nft__teammembers border">
            <img src="/Assets/a.png" alt="" />
            <h1>Artist</h1>
            <h4 className="my-2">Mike G</h4>
            <i className="bi bi-discord" />
          </div>
          <div className="nft__teammembers border">
            <img src="/Assets/b.png" alt="" />
            <h1>Developer</h1>
            <h4 className="my-2">Munim</h4>
            <i className="bi bi-discord" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
